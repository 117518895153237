






































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ZuzhijiagouList } from "@/request/manage";
import {
  GetTemplatesOfGroup,
  GetDataExportTemplates,
  GetTopicsOfTemplate,
  PostDataExport,
} from "@/request/storehouse";
import { getTopicOpt } from "@/request/topic";
import { CreatorList } from "@/request/common";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import TimeSelect from "@/components/time-select2.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    TimeSelect,
  },
})
export default class Name extends Vue {
  private topicOpt: any = [];
  private institutions: any[] = [];
  private creators: any[] = [];
  private zhenciStatus: any = [
    "失效",
    "草稿",
    "无效",
    "待确认",
    "待审核",
    "已打回",
    "已通过",
    "锁定",
    "失活",
  ];
  private filters: any = {
    表格名称: "",
    institution_id: "",
    institution_ids: [],
    性别: "",
    最低年龄: 0,
    最高年龄: 150,
    姓名身份: "",
    开始时间: "",
    结束时间: "",
    时间段: "",
    条件: "全部条件",
    行研究指标: [],
    列研究指标: [],
    诊次时间: "全部",
    所属课题: {},
    time: [],
    ageWay: "全部",
    export_answer_code: false,
  };
  private ifAllHospital: any = false;
  private searchedTemplates: any[] = [];
  private rightTemplatesGroup: any[] = [];
  private timeOpt: any = ["近3个月", "近6个月", "近1年"];
  private get center() {
    return this.$store.state.center;
  }
  private get user() {
    return this.$store.state.user;
  }
  /**
   * @description 测评量统计概览-重点人群测评量分布状况-统计时间改变
   */
  private timeChange(e: any) {
    this.filters = e;
  }
  /**
   * @description 获得可选创建者列表
   */
  private getCreatorsList() {
    const params: any = {
      project_id: this.center.project_id,
      institution_ids: [this.filters.institution_id],
    };
    if (this.filters.project_id == "全部") {
      params.project_id = "";
    }
    CreatorList(this, params).then((data: any) => {
      this.creators = data;
    });
  }
  /**
   * @description 获得可选的组织结构列表
   */
  private getZuzhijiagouList(text: any) {
    const params: any = {
      params: {
        search: text,
        project_id: this.center.project_id,
      },
    };
    ZuzhijiagouList(this, params).then((data: any) => {
      this.institutions = data;
    });
  }
  /**
   * @description 获取当前分组下的可选模板列表
   */
  private fetchTemplates() {
    const params: any = {
      params: {
        project_id: this.center.project_id,
        group_id: this.center.group_id,
      },
    };
    GetTemplatesOfGroup(this, params).then((data: any) => {
      this.searchedTemplates = data;
    });
  }
  /**
   * @description 获得右侧可选的模板列表
   */
  private fetchRightTemplates() {
    const loading = this.$loading({
      lock: true,
      text: "导出中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
      target: ".addExportTable",
    });
    const params: any = {
      params: {
        group_id: this.center.group_id,
        project_id: this.center.project_id,
      },
    };
    GetDataExportTemplates(this, params, loading)
      .then((data: any) => {
        data.map((item: any) => {
          item.opened = false;
          item.choice = false;
        });
        if (data.length > 0) {
          data[0].opened = true;
        }
        // 默认选中第一诊次
        if (data.length > 1) {
          data[1].choice = true;
          data[1].templates.forEach((d: any) => {
            d.choice = true;
          });
        }

        this.rightTemplatesGroup = data;
      })
      .finally(() => {
        loading.close();
      });
  }
  /**
   * @description 添加研究指标变量
   */
  private addTiaojian() {
    this.filters["行研究指标"].push({ result: {}, selected: {} });
  }
  /**
   * @description 搜索模板下具体的字段
   */
  private searchItems(l: any) {
    const params: any = {
      params: {
        template_id: l.template_id,
      },
    };
    GetTopicsOfTemplate(this, params).then((data: any) => {
      l.items = data;
      this.$forceUpdate();
    });
  }
  /**
   * @description 删除一个条件
   */
  private deleteTiaojian(index: any) {
    this.filters["行研究指标"].splice(index, 1);
  }
  /**
   * @description 全选或取消全选
   */
  private triggerAll(value: any) {
    this.rightTemplatesGroup.forEach((tGroup: any) => {
      tGroup["choice"] = value;
      tGroup["templates"].forEach((template: any) => {
        template["choice"] = value;
      });
    });
  }

  /**
   * @description 外层选中变化
   */
  private tGroupChange(value: any, tGroup: any) {
    // if (value === true) {
    //   this.rightTemplatesGroup.forEach((tgroup: any) => {
    //     tgroup["choice"] = false;
    //     tgroup["templates"].forEach((template: any) => {
    //       template["choice"] = false;
    //     });
    //   });
    // }
    tGroup["choice"] = value;
    tGroup["templates"].forEach((template: any) => {
      template["choice"] = value;
    });
  }

  /**
   * @description 里层选中变化
   */
  private templateChange(value: any, tGroup: any, t: any) {
    // this.rightTemplatesGroup.forEach((tgroup: any) => {
    //   if (
    //     tGroup["project_id"] !== tgroup["project_id"] &&
    //     tGroup["zhenci"] !== tgroup["zhenci"]
    //   ) {
    //     tgroup["choice"] = false;
    //     tgroup["templates"].forEach((template: any) => {
    //       template["choice"] = false;
    //     });
    //   }
    // });
    if (value === true) {
      let allSelect = true;
      tGroup["templates"].forEach((template: any) => {
        if (!template["choice"]) {
          allSelect = false;
        }
      });
      tGroup["choice"] = allSelect;
    } else {
      tGroup["choice"] = false;
    }
    t["choice"] = value;
  }

  /**
   * @description 导出
   */
  private startExport() {
    let ifChoice: any = false;
    this.rightTemplatesGroup.forEach((element: any) => {
      if (element.choice) {
        ifChoice = true;
      }
      element.templates.forEach((ele: any) => {
        if (ele.choice) {
          ifChoice = true;
        }
      });
    });
    if (!this.filters["表格名称"]) {
      this.$message.warning("表格名称不能为空");
      return;
    }
    if (!this.filters.institution_id) {
      this.$message.warning("请选择要导出的所属层级");
      return;
    }
    if (!this.filters.export_format) {
      this.$message.warning("请选择导出格式");
      return;
    }
    if (!ifChoice) {
      this.$message.warning("请选择需要导出的量表或诊次");
      return;
    }
    this.filters["列研究指标"] = this.rightTemplatesGroup;
    const params: any = {
      表格名称: this.filters["表格名称"],
      project_id: this.center.project_id,
      institution_id: this.filters.institution_id,
      user_ids: this.filters.user_ids,
      时间段: this.filters["诊次时间"],
      discussion_ids: this.filters["所属课题"]._id,
      创建人: this.filters["创建人"],
      条件: this.filters["条件"],
      格式: this.filters["export_format"],
      列研究指标: this.filters["列研究指标"],
      export_answer_code: this.filters["export_answer_code"],
      format: this.filters.export_format,
      诊次状态: this.filters["诊次状态"],
    };
    // 诊次时间段
    if (this.filters["诊次时间"] === "自定义") {
      params["开始时间"] = this.filters["开始时间"];
      params["结束时间"] = this.filters["结束时间"];
    }
    if (this.filters["行研究指标"].length > 0) {
      const arr: any[] = [];
      this.filters["行研究指标"].map((item: any) => {
        const i = JSON.parse(JSON.stringify(item.selected));
        i.condition = item.result.condition;
        i.value = item.result.value;
        arr.push(i);
      });
      params["行研究指标"] = arr;
    }
    const loading = this.$loading({
      lock: true,
      text: "导出中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostDataExport(this, params, loading).then((data: any) => {
      // window.open(
      //   window.API_ROOT + "api/wajue/download?filename=" + data.file_name
      // );
      loading.close();
      this.$confirm(
        "数据导出中，您可切换或者关闭页面，导出进度和下载数据表格可至右上角导出中心。",
        "导出",
        {
          confirmButtonText: "跳转至下载中心查看",
          cancelButtonText: "返回专病库",
          customClass: "commonConfirm",
          // showClose: false,
          // closeOnClickModal:false,
          distinguishCancelAndClose: true,
          type: "warning",
        }
      )
        .then(() => {
          this.$router.push("/main/export/export-result");
        })
        .catch((e: any) => {
          if (e == "cancel") {
            this.$router.back();
          }
        });
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.getZuzhijiagouList(" ");
    this.fetchTemplates();
    this.fetchRightTemplates();
    getTopicOpt(this).then((res: any) => {
      this.topicOpt = res;
    });
  }
}
